import { RuntimeEnvironments, MSAEnvironments } from './runtime-environments';

export const environment = {
  production: true,
  performance: false,
  internal: false,
  deployment: 'production',
  site: 'retailExternal',
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers

  GLOBAL_HEADER_V8_JS: "https://n4v.web.vanguard.com/secure-site-components.js",

  LOGON_URL: 'https://logon.vanguard.com/logon?TARGET_OVERRIDE=https://historical-documents.web.vanguard.com',
  MEDALLIA_LIB:
    'https://mdl-medallia-digital.vanguard.com/pkg/1.0.0/web/external_libs/wdcusprem/2940/onsite/medallia-digital-embed.js',
  SECURE_MESSAGE_URL: 'https://personal.vanguard.com/us/SMCInformationCenter',
  // CONSUMER_API: 'https://api.ecs.us-east-1.rett.c1.vanguard.com/xs1-secure-site-consumer-api/',
  GRAPHQL_URI: 'https://apps.ecs.retp.c1.vanguard.com/xs1-secure-site-consumer-api/graphql',
  LEGACY_URL: 'https://personal.vanguard.com/us/XHTML/archivedDocuments',
  GATEKEEPER_FEATURE_ID: 'LegacyRedirectCheck',

  // TEALEAF_LIB: 'https://api.ecs.us-east-1.rete.c1.vanguard.com/lah-statements-consumer/tealeaf',

  FLOODLIGHT_RTE: RuntimeEnvironments.PROD,
  MSA_ENVIRONMENT: MSAEnvironments.PRODUCTION,
  PERSONAL_DOMAIN: 'https://personal.vanguard.com',

  ADOBE_TARGET_ANTIFLICKER_JS:
    'https://corp.at2.assets.vgdynamic.info/files/js/core/antiflicker.min.js',
  C3_SERVERLESS_BUNDLE_JS:
    "https://mc3-static-assets.web.vanguard.com/vg-ccc-serverless/latest/vg-ccc-serverless.js",

  HISTORICAL_DOCUMENTS: {
    BASE_URL: 'https://personal1.vanguard.com/hdw-historical-documents-consumer-api',
    CONSUMER_URL: '/historical-documents/consumer',
    LOGGER: '/client-log',
    CAAS_URL: '/historical-documents/caas',
    TEALEAF_LIB: '/tealeaf',
    PDF_URL: '/historical-documents/pdf',
    GATEKEEPER_URL: '/gatekeeper',
    HISTORICAL_DOCUMENT_TYPES: '/historical-documents/historical-document-types'
  },
  downloadAccountLink: {
    label: 'Download center',
    url: 'https://personal1.vanguard.com/ofu-open-fin-exchange-webapp/ofx-welcome',
  },
};
